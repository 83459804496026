import React from "react";
import { useStaticQuery, graphql } from 'gatsby';

import SEO from "../components/seo"
import Support from '../containers/Support';
import FaqSection from '../containers/FaqSection';
import MarkSection from '../containers/MarkSection';
import WorkSection from '../containers/WorkSection';
import CallToAction from '../containers/CallToAction';
import Testimonial from '../containers/Testimonial';
import AboutUsSection from '../containers/AboutUsSection';

const Community = () => {

  const data = useStaticQuery(graphql`
    query {
      dataJson {
        WORKDATA {
          community {
            id
            title
            description
            icon {
              publicURL
            }
          }
        }
        COMMUNITYHEADER {
          seo {
            title
            description
            url
            imageAlt
            twitterProfile
            image {
              publicURL
            }
            twiterImage {
              publicURL
            }
            keywords
          }
        }
      }
    }
  `);

  const { community } = data.dataJson.WORKDATA;
  const { seo } = data.dataJson.COMMUNITYHEADER;

  return (
    <>
      <SEO metaData={seo} lang="" />
      <AboutUsSection />
      <WorkSection items={community} />
      <Support />
      <MarkSection />
      <Testimonial />
      <FaqSection />
      <CallToAction />
    </>
  );
}

export default Community