import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { openPopupWidget } from "react-calendly";
import { useStaticQuery, graphql } from 'gatsby';

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Card from '../../common/components/Card';
import Image from '../../common/components/Image';
import FeatureBlock from '../../common/components/FeatureBlock';
import Container from '../../common/components/UI/Container';

const Mark = ({
  sectionWrapper,
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  textAreaRow,
  imageAreaRow,
  imageWrapper,
  imageWrapperOne,
  imageWrapperTwo,
  sectionSubTitle,
  btnStyle,
}) => {

  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        MARK {
          sectionText
          sectionTitle
          sectionDescription
          buttonLabel
          buttonPath
          insignia {
            childImageSharp{
              fluid {
                src
              }
            }
          }
          imageAlt
        }
      }
    }
  `);

  const {
    insignia,
    imageAlt,
    sectionText,
    sectionTitle,
    sectionDescription,
    buttonLabel,
    buttonPath
  } = Data.dataJson.MARK

  const calendlyData = {
    url: "https://calendly.com/portaregroup/experto",
    prefill: {},
    pageSettings: {},
    utm: {}
  }

  const handleCalendly = () => {
    openPopupWidget(calendlyData)
  };

  return (
    <Box {...sectionWrapper} id="payments">
      <Container className="control-sec-container payment">
        <Box {...row} {...imageAreaRow}>
          <Box {...col} {...imageArea}>
            <Card {...imageWrapper} {...imageWrapperTwo} className="cardExtraImage" >
              <Fade right>
                <Image src={insignia.childImageSharp.fluid.src} alt={imageAlt} />
              </Fade>
            </Card>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box {...row} {...textAreaRow}>
          <Box {...col} {...textArea}>
            <Text content={sectionText} {...sectionSubTitle} className="showText" style={{ display: 'none' }} />
            <FeatureBlock className="breakpointText"
              title={ <Heading content={sectionTitle} {...title} /> }
              description={ <Text content={sectionDescription} {...description} /> }
              button={
                <Button  {...button}  {...btnStyle} title={buttonLabel} onClick={handleCalendly} />
              }
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Mark.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyle: PropTypes.object,
};

Mark.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '40px', '80px', '80px', '80px'],
    pb: ['20px', '40px', '80px', '180px', '280px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  textAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: [1, 1, '45%', '45%', '45%'],
    zIndex: '1',
  },
  imageArea: {
    width: [0, 0, '30%', '45%', '45%'],
    flexBox: true,
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    pointerEvents: 'none',
  },
  imageWrapperTwo: {
    alignSelf: 'flex-start',
    mt: ['0px', '0px', '100px', '0px', '-50px'],
    ml: ['-250px', '-250px', '-180px', '-220px', '-420px'],
    pointerEvents: 'none',
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: ['left', 'left'],
    fontSize: '14px',
    letterSpacing: '0.11em',
    fontWeight: 'bolder',
    color: '#0477BF',
    textTransform: 'uppercase',
    mb: '10px',
  },
  title: {
    fontSize: ['24px', '26px', '30px', '36px', '48px'],
    fontWeight: 'bolder',
    lineHeight: '1.1',
    color: 'headingColor',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '420px', '420px'],
    textAlign: ['center', 'left'],
  },
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: '33px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    textAlign: ['center', 'left'],
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: 'bolder',
    color: 'white',
    borderRadius: '4px',
    colors: 'primary',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: 'bolder',
  },
};

export default Mark;
