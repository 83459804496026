import React from 'react';

import BlogPost from '../../common/components/BlogPost';
import Container from '../../common/components/UI/Container';
import SectionWrapper, { FeatureWrapper } from './workSection.style';

const WorkSection = ({ items }) => {
  return (
    <SectionWrapper id="work">
      <Container>
        <FeatureWrapper>
          {items.map((item) => (
            <BlogPost
              key={`feature_key${item.id}`}
              thumbUrl={item.icon.publicURL}
              title={item.title}
              excerpt={item.description}
            />
          ))}
        </FeatureWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default WorkSection;
