import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { openPopupWidget } from "react-calendly";
import { useStaticQuery, graphql } from 'gatsby';

import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Card from '../../common/components/Card';
import Button from '../../common/components/Button';
import FeatureBlock from '../../common/components/FeatureBlock';
import AboutUsSectionWrapper from './aboutUsSection.style';

const AboutUsSection = ({
  row,
  col,
  title,
  description,
  textArea,
  featureTitle,
  btnStyle,
}) => {

  const calendlyData = {
    url: "https://calendly.com/portaregroup/experto",
    prefill: {},
    pageSettings: {},
    utm: {}
  }

  const handleCalendly = () => {
    openPopupWidget(calendlyData)
  };

  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        COMMUNITYHEADER {
          sectionTitle
          sectionDescription
          buttonLabel
          buttonPath
          image1 {
            childImageSharp {
              fluid(maxWidth: 505, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image2 {
            childImageSharp {
              fluid(maxWidth: 505, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image3 {
            childImageSharp {
              fluid(maxWidth: 505, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          items {
            id
            title
            icon
          }
        }
      }
    }
  `);

  const { 
    sectionTitle, 
    sectionDescription,
    buttonLabel,
    buttonPath,
    image1, 
    image2, 
    image3,
    items,
  } = Data.dataJson.COMMUNITYHEADER


  return (
    <AboutUsSectionWrapper id="AboutUsSection">
      <Box className="row" {...row}>
        <Box className="col sectionBreakPoint" {...col}>
          <Card className="group-gallery">
            <Box className="col1">
              <Fade top delay={30}>
                <Image
                  fluid={
                    (image1 !== null) | undefined
                      ? image1.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 1"
                />
              </Fade>
              <Fade left delay={60}>
                <Image
                  fluid={
                    (image3 !== null) | undefined
                      ? image3.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 3"
                />
              </Fade>
            </Box>
            <Box className="col2">
              <Fade bottom delay={90}>
                <Image
                  fluid={
                    (image2 !== null) | undefined
                      ? image2.childImageSharp.fluid
                      : {}
                  }
                  objectFit="contain"
                  alt="Feature Image 2"
                />
              </Fade>
            </Box>
          </Card>
        </Box>
        <Box className="col" {...col}>
          <Box {...textArea}>
            <FeatureBlock
              title={
                <Heading
                  content={sectionTitle}
                  {...title}
                />
              }
              description={
                <Text
                  content={sectionDescription}
                  {...description}
                />
              }
            />
          </Box>
          <Box {...textArea}>

            {items.map((feature, index) => (
              <FeatureBlock
                key={`feature_point-${index}`}
                icon={<i className={feature.icon} />}
                iconPosition="left"
                title={<Heading content={feature.title} {...featureTitle} />}
              />
            ))}
            <Button {...btnStyle} title={buttonLabel} onClick={handleCalendly} />
          </Box>
        </Box>
      </Box>
    </AboutUsSectionWrapper>
  );
};

AboutUsSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  textArea: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

AboutUsSection.defaultProps = {
  // About us section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // About us section col default style
  col: {
    width: [1, '100%', '50%'],
  },
  // About us section text area default style
  textArea: {
    maxWidth: '490px',
    pl: '40px',
    pr: '30px'
  },
  // About us section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.1',
    fontWeight: 'bolder',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '40px',
  },
  // About us section description default style
  description: {
    fontSize: '16px',
    color: 'textColor',
    lineHeight: '1.75',
    mt: '15px',
    mb: '35px',
  },

  // feature title default style
  featureTitle: {
    fontSize: '16px',
    fontWeight: '400',
    color: 'textColor',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // Button default style
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['15px', '16px'],
    fontWeight: 'bolder',    
    colors: 'main',
    backgroundColor: 'main',

  },
};

export default AboutUsSection;
