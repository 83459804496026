import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Image from '../../common/components/Image';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/ContainerTwo';
import { TitleSection } from '../index.style';

import Section, {
  ContentWrapper,
  Content,
  HelpBlock,
  Illustration,
} from './support.style';

const Support = ({
  secHeading,
  secTitleWrapper
}) => {
  
  const data = useStaticQuery(graphql`
    query {
      dataJson {
        SUPPORT {
          illustration {
            childImageSharp{
              fluid {
                src
              }
            }
          }
          imageAlt
          title
          slogan
          items {
            id
            title
            desc
            icon {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const { title, slogan, illustration, imageAlt, items } = data.dataJson.SUPPORT;

  return (
    <Section>
      <Container>
        <ContentWrapper>
          <Content>
      
            <TitleSection className="showText" style={{ display: 'none' }} >
              <Heading as="h5" content={title} />
              <Heading content={slogan} />
            </TitleSection> 

            {items.map((help) => (
              <HelpBlock key={help.id}>
                <div className="icon">
                  <Image
                    src={help.icon.childImageSharp.fluid.src}
                    alt={help.title}
                  />
                </div>
                <div className="content">
                  <p>{help.desc}</p>
                </div>
              </HelpBlock>
            ))}
          </Content>
          <Illustration>
            <Image src={illustration.childImageSharp.fluid.src} alt={imageAlt} />
          </Illustration>
        </ContentWrapper>
      </Container>
    </Section>
  );
};

Support.propTypes = {
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
}

Support.defaultProps = {
  secTitleWrapper: {
    mb: ['25px', '35px'],
    textAlign: 'left',
    display: 'none'
  },
  secHeading: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: 'bolder',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '5px',
    lineHeight: '1.1',
    textAlign: 'left',
  },
}

export default Support;
